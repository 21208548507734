<div class="main-logo-element">
  @switch (size) {
    @case ("lg") {
      <img src="assets/images/logos/logo.svg" alt="Logo" [style.height.px]="logoHeight" />
      <!--      <div class="beta">Free Beta</div>-->
    }
    @case ("md") {
      <img src="assets/images/logos/logo.svg" alt="Logo" [style.height.px]="logoHeight" />
      <!--      <div class="beta">Free Beta</div>-->
    }
    @case ("sm") {
      <img src="assets/images/logos/logo_sm.svg" alt="Logo" [style.height.px]="logoHeight" />
    }
  }
</div>
