import { Injectable } from '@angular/core';
import {
  BackendService,
  BaseApiService,
  BaseStatusResponse,
  RequestFacadeModel,
  RequestModel,
  RequestType,
} from '@cf/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RegisterFormDto } from '../auth';
import { ResetPasswordByTokenValidateDto } from './dtos';

import { ChangePasswordByTokenDto, ForgotPasswordDto } from './dtos';
import { ICalendarSettings, IDeleteUser, IUser, IUserNotice } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseApiService {
  constructor(public override backendService: BackendService) {
    super(backendService, 'users');
  }

  public userEmail(token: string) {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl(),
      skipNotify: true,
    }).withQuery({ token });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });
    return this.send<any>(requestFacade);
  }

  public deleteAccount(dto?: IDeleteUser) {
    const request: RequestModel<unknown> = new RequestModel({
      url: this.getFullUrl('cancel'),
      requestBody: dto ?? {},
    });
    const requestFacade: RequestFacadeModel<unknown> = new RequestFacadeModel({
      requestType: RequestType.post,
      request,
    });
    return this.send<unknown, unknown>(requestFacade);
  }

  public register(registerFormDto: RegisterFormDto): Observable<IUser> {
    const request: RequestModel<RegisterFormDto> = new RequestModel<RegisterFormDto>({
      url: this.getFullUrl(),
      requestBody: registerFormDto,
      skipNotify: true,
    });
    const requestFacade: RequestFacadeModel<RegisterFormDto> =
      new RequestFacadeModel<RegisterFormDto>({
        requestType: RequestType.post,
        request,
      });
    return this.send<IUser, RegisterFormDto>(requestFacade);
  }

  public forgotPassword(forgotPasswordDto: ForgotPasswordDto): Observable<BaseStatusResponse> {
    const request: RequestModel<ForgotPasswordDto> = new RequestModel<ForgotPasswordDto>({
      url: this.getFullUrl('reset-password'),
      requestBody: forgotPasswordDto,
    });
    const requestFacade: RequestFacadeModel<ForgotPasswordDto> =
      new RequestFacadeModel<ForgotPasswordDto>({
        requestType: RequestType.post,
        request,
      });
    return this.send<BaseStatusResponse, ForgotPasswordDto>(requestFacade);
  }

  public validateTokenFromForgotPassword(token: string): Observable<BaseStatusResponse> {
    const request: RequestModel<ResetPasswordByTokenValidateDto> =
      new RequestModel<ResetPasswordByTokenValidateDto>({
        url: this.getFullUrl('reset-password/validate'),
        requestBody: { token },
      });
    const requestFacade: RequestFacadeModel<ResetPasswordByTokenValidateDto> =
      new RequestFacadeModel<ResetPasswordByTokenValidateDto>({
        requestType: RequestType.post,
        request,
      });
    return this.send<BaseStatusResponse, ResetPasswordByTokenValidateDto>(requestFacade);
  }

  public changePasswordByToken(data: ChangePasswordByTokenDto): Observable<BaseStatusResponse> {
    const request: RequestModel<ChangePasswordByTokenDto> =
      new RequestModel<ChangePasswordByTokenDto>({
        url: this.getFullUrl('reset-password'),
        requestBody: data,
      });
    const requestFacade: RequestFacadeModel<ChangePasswordByTokenDto> =
      new RequestFacadeModel<ChangePasswordByTokenDto>({
        requestType: RequestType.put,
        request,
      });
    return this.send<BaseStatusResponse, ChangePasswordByTokenDto>(requestFacade);
  }

  public notices(): Observable<IUserNotice[]> {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl('notices'),
    }).withQuery({ limit: 1 });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });
    return this.send<any, any>(requestFacade).pipe(map((res) => res.results));
  }

  public noticeDismiss(id: number): Observable<IUserNotice[]> {
    const request: RequestModel<any> = new RequestModel({
      url: this.getFullUrl(`notices/${id}/close`),
      requestBody: {},
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.put,
      request,
    });
    return this.send<any, any>(requestFacade);
  }

  getCalendarSettings(): Observable<ICalendarSettings> {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl('calendar-settings'),
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });
    return this.send<ICalendarSettings, unknown>(requestFacade);
  }

  updateCalendarSettings(dto: Partial<ICalendarSettings>): Observable<ICalendarSettings> {
    const request: RequestModel<Partial<ICalendarSettings>> = new RequestModel({
      url: this.getFullUrl('calendar-settings'),
      requestBody: dto,
    });
    const requestFacade: RequestFacadeModel<Partial<ICalendarSettings>> = new RequestFacadeModel({
      requestType: RequestType.patch,
      request,
    });
    return this.send<ICalendarSettings, unknown>(requestFacade);
  }
}
