import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@tolgee/ngx';

export enum DayEnum {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

@Pipe({
  name: 'dayOfWeekTranslate',
  pure: false,
})
export class DayOfWeekTranslatePipe implements PipeTransform {
  translateService = inject(TranslateService);
  #cache = new Map<string, string>();

  transform(value: DayEnum, isShort = false): string {
    const cacheKey = `${value}-${isShort}-${this.translateService.language}`;
    const cached = this.#cache.get(cacheKey);
    if (cached) {
      return cached;
    }

    const result = this.getStr(value, isShort);
    this.#cache.set(cacheKey, result);
    return result;
  }

  getStr(day: DayEnum, isShort: boolean) {
    if (!day) {
      return '';
    }

    if (day === DayEnum.Sunday) {
      return isShort
        ? this.translateService.instant('day_of_week.sunday_short')
        : this.translateService.instant('day_of_week.sunday');
    }

    if (day === DayEnum.Monday) {
      return isShort
        ? this.translateService.instant('day_of_week.monday_short')
        : this.translateService.instant('day_of_week.monday');
    }

    if (day === DayEnum.Tuesday) {
      return isShort
        ? this.translateService.instant('day_of_week.tuesday_short')
        : this.translateService.instant('day_of_week.tuesday');
    }

    if (day === DayEnum.Wednesday) {
      return isShort
        ? this.translateService.instant('day_of_week.wednesday_short')
        : this.translateService.instant('day_of_week.wednesday');
    }

    if (day === DayEnum.Thursday) {
      return isShort
        ? this.translateService.instant('day_of_week.thursday_short')
        : this.translateService.instant('day_of_week.thursday');
    }

    if (day === DayEnum.Friday) {
      return isShort
        ? this.translateService.instant('day_of_week.friday_short')
        : this.translateService.instant('day_of_week.friday');
    }

    if (day === DayEnum.Saturday) {
      return isShort
        ? this.translateService.instant('day_of_week.saturday_short')
        : this.translateService.instant('day_of_week.saturday');
    }

    return '';
  }
}
